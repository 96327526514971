import React from "react";
import { Link } from "gatsby";

import Layout from "../../components/layout";

const NotFoundPage = ({ location }) => (
  <Layout
    seo={{ title: "Page not found", href: location.pathname, lang: "en" }}
  >
    <section className="container-fluid page_404">
      <h2>This page doesn't exist</h2>
      <br />
      <div className="btn_wrapper">
        <Link to="/en/" className="btn_custom">
          Home
        </Link>
      </div>
      <img
        className="svg"
        src={require("../../assets/img/ico_404.svg")}
        alt=""
      />
    </section>
  </Layout>
);

export default NotFoundPage;
